import ky from 'ky';

import {LabelInstancePaginationResponse} from '~redux/types/images';

import {getOptions} from './api';
import {processFilters} from './filters.utils';
import {ImagesDataPayload} from './images';

type LabelInstancesPayload = ImagesDataPayload;

export const getLabelInstancesData = (
  projectId: string,
  payload: LabelInstancesPayload,
  areMachinePredictions: boolean,
  reactQuerySignal: AbortSignal | undefined,
): Promise<LabelInstancePaginationResponse> => {
  const {filters, pagination} = payload;

  const endpoint = areMachinePredictions
    ? `${process.env.AUTH_API}/label_instances/${projectId}/machine`
    : `${process.env.AUTH_API}/label_instances/${projectId}`;

  return ky
    .post(
      endpoint,
      getOptions<LabelInstancesPayload>({
        apiVersion: 3,
        signalId: `getLabelInstancesData-${projectId}`,
        reactQuerySignal,
        data: {
          filters: processFilters(filters),
          pagination,
        },
      }),
    )
    .json<LabelInstancePaginationResponse>();
};

export const getLabelInstancesIds = (
  projectId: string,
  filters: ImagesDataPayload['filters'],
  reactQuerySignal: AbortSignal | undefined,
): Promise<{ids: string[]}> => {
  return ky
    .post(
      `${process.env.AUTH_API}/label_instances/${projectId}/human/ids`,
      getOptions<ImagesDataPayload['filters']>({
        apiVersion: 3,
        signalId: `getLabelInstancesIds-${projectId}`,
        reactQuerySignal,
        data: processFilters(filters),
      }),
    )
    .json<{ids: string[]}>();
};

type CloneLabelInstancesPayload = {
  ids: string[];
  targetLabelId: string;
  targetModelId: string;
};

export const cloneLabelInstances = (projectId: string, payload: CloneLabelInstancesPayload) => {
  return ky.post(
    `${process.env.AUTH_API}/label_instances/${projectId}/human/clone`,
    getOptions({
      signalId: 'cloneLabelInstances',
      data: payload,
    }),
  );
};

type ChangeClassLabelInstancesPayload = {
  ids: string[];
  targetLabelId: string;
  targetModelId: string;
};

export const changeClassLabelInstances = (projectId: string, payload: ChangeClassLabelInstancesPayload) => {
  return ky.post(
    `${process.env.AUTH_API}/label_instances/${projectId}/human/change_class`,
    getOptions({
      signalId: 'changeClassLabelInstances',
      data: payload,
    }),
  );
};

type DeleteLabelInstancesPayload = {
  ids: string[];
};

export const deleteLabelInstances = (projectId: string, payload: DeleteLabelInstancesPayload) => {
  return ky.post(
    `${process.env.AUTH_API}/label_instances/${projectId}/human/delete`,
    getOptions({
      signalId: 'deleteLabelInstances',
      data: payload,
    }),
  );
};
