import {useTranslation} from 'i18n';
import {MutableRefObject, useMemo} from 'react';
import {RegisterOptions} from 'react-hook-form';

import {useModelsQuery} from '~api/models.queries';
import {getAliasFromItems} from '~utils/miscUtils';
import {modelIdQueryParam, useQueryParam} from '~utils/routeUtil';
import {LabelItem, LabelType} from '~redux/types/images';
import {Model} from '~redux/types/models';
import {selectCurrentSession} from '~redux/reducers/imageReducer';
import {useAppSelector} from '~redux/index';

import {ImageAnnotatorMode, useImageAnnotatorMode} from '~components/images/utils/image-mode-mixins';
import {useCurrentModel} from 'src/contexts/ModelContext';

export function useIsCurrentModelAutomlEnabled() {
  const models = useModelsQuery();
  const [currentModelId] = useQueryParam(modelIdQueryParam);

  return useMemo(() => {
    if (models.data && currentModelId) {
      const model = models.data.find((model) => model.id === currentModelId);
      return model?.automlEnabled ?? false;
    }
    return false;
  }, [models.data, currentModelId]);
}

function useGlobalLabelItems() {
  const modelsQuery = useModelsQuery();
  return useMemo(() => {
    if (modelsQuery.data) {
      // Map over all models to get a list of all available labels
      return modelsQuery.data
        .filter((model) => model.labels.length > 0)
        .map((model) => model.labels)
        .flat();
    }
    return [];
  }, [modelsQuery.data]);
}

function useCurrentModelLabelItems(model?: Model) {
  const {currentModel} = useCurrentModel();
  return model?.labels || currentModel?.labels || [];
}

/**
 * @param from - either "current-model" (only labels from the current model will be returned),
 * "project" (only labels from the project will be returned) or "current-model-or-project" (labels from the current model
 * will be returned if available, otherwise labels from the project will be returned)
 * @param type - if provided, only labels of the given type will be returned (e.g. "polygon")
 * @param withRespectToSmartTeachSession - if true, the label items will be filtered based on the current smart teach session
 * @returns a list of label items
 */
export function useLabelItems({
  from,
  type,
  /**
   * If true, the label items will be filtered based on the current smart teach session
   * If false, the label items will be returned regardless of the current smart teach session
   * @default true
   */
  withRespectToSmartTeachSession = true,
  model,
}: {
  from: 'current-model' | 'project' | 'current-model-or-project';
  type?: LabelType;
  withRespectToSmartTeachSession?: boolean;
  model?: Model;
}): LabelItem[] {
  const currentModelLabelItems = useCurrentModelLabelItems(model);
  const globalLabelItems = useGlobalLabelItems();
  const session = useAppSelector(selectCurrentSession);
  const imageAnnotatorMode = useImageAnnotatorMode();

  const hasModelLabels = currentModelLabelItems.length > 0;
  const hasProjectLabels = globalLabelItems.length > 0;
  let labels: LabelItem[];

  if (from === 'current-model' && hasModelLabels) {
    labels = currentModelLabelItems;
  } else if (from === 'project' && hasProjectLabels) {
    labels = globalLabelItems || [];
  } else if (from === 'current-model-or-project' && (hasModelLabels || hasProjectLabels)) {
    labels = hasModelLabels ? currentModelLabelItems : globalLabelItems;
  } else {
    return [];
  }

  if (type !== undefined) {
    labels = labels.filter((item) => item.type.toLowerCase() === type);
  }

  if (
    withRespectToSmartTeachSession &&
    imageAnnotatorMode === ImageAnnotatorMode.smartTeachSession &&
    session?.meta?.label !== undefined
  ) {
    labels = labels.filter((item) => item.id === session.meta?.label);
  }
  return labels;
}

/**
 * Return the current Model's label **IDs** if available, otherwise return the global (project) label **IDs**.
 * This uses {@link useLabelItems} internally.
 */
export function useLabelIds(...args: Parameters<typeof useLabelItems>): string[] {
  const labelItems = useLabelItems(...args);
  return useMemo(() => labelItems.map((item) => item.id), [labelItems]);
}

export function useModelNameValidationRules(currentModel?: Model): RegisterOptions {
  const {t} = useTranslation('models');
  const modelsQuery = useModelsQuery();
  const trimmedCurrentModelName = currentModel?.name?.trim().toLowerCase();

  return {
    required: {
      value: true,
      message: t('errorModelNameMustNotBeEmpty'),
    },
    maxLength: {
      value: 50,
      message: t('errorModelNameTooLong'),
    },
    validate: {
      unique: (newValue) => {
        const trimmedNewValue = newValue.trim().toLowerCase();
        if (trimmedCurrentModelName === trimmedNewValue) {
          return true;
        }
        return (
          !modelsQuery.data?.find((model) => model.name.trim().toLowerCase() === trimmedNewValue) ||
          (t('errorModelNameAlreadyExists') as string)
        );
      },
    },
  };
}

export function useLabelAlias(labelId: string | undefined) {
  const allLabelItems = useLabelItems({from: 'project'});
  return useMemo(() => {
    return labelId ? getAliasFromItems(labelId, allLabelItems) : '';
  }, [allLabelItems, labelId]);
}

/**
 * NOTE: this uses a ref for the forbidden names array to improve performance, see LabelSettingsTabContent
 */
export function useLabelValidationRules(
  forbiddenNamesRef: MutableRefObject<string[]>,
  currentAlias?: string | null,
): RegisterOptions {
  const {t} = useTranslation('models');
  const trimmedCurrentAlias = currentAlias?.trim().toLowerCase();

  return {
    required: {
      value: true,
      message: t('errorIsEmpty', {name: t('addClassInputPlaceholder')}),
    },
    validate: {
      unique: (newValue) => {
        const trimmedNewValue = newValue.trim().toLowerCase();

        // 1. check the number of occurences of the new value
        const currentOccurences = forbiddenNamesRef.current.filter(
          (v) => v.trim().toLowerCase() === trimmedNewValue,
        ).length;

        // 2. allow the newValue to occur exactly ONCE when we're in the process of updating
        // the currentAlias. In all other cases, the newValue MUST NOT be present in the list of
        // forbidden names.
        const allowedOccurences = trimmedCurrentAlias === trimmedNewValue ? 1 : 0;
        if (currentOccurences > allowedOccurences) {
          return t('errorAlreadyExists', {name: t('addClassInputPlaceholder')}) as string;
        } else {
          return true;
        }
      },
    },
  };
}
