import {useQuery} from '@tanstack/react-query';

import {confusionMatrixCellIdQueryParam, useQueryParam} from '~utils/routeUtil';
import {
  useConfusionCellFromQueryParams,
  useEvaluationCellFromQueryParams,
  useSimilaritySearchFromQueryParams,
} from '~hooks/imagesOverview';
import {selectCurrentProjectId} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

import {useLabelIds} from '~components/models/model.hooks';
import {
  getLabelDistributionCounts,
  LabelDistributionCounts,
} from '~components/statistics/label-distribution/chartUtils';
import {MAX_IMAGES_TOTAL} from 'src/constants/constants';
import {useCurrentModel} from 'src/contexts/ModelContext';
import {getImagesData, getImagesIds, ImageFiltersPayload, ImagesDataPayload, SortAndCountArgs} from './images';

export const ImageQueryKeys = {
  all: ['images'] as const,
  imagesData: (projectId: string, params: ImagesDataPayload) => [...ImageQueryKeys.all, projectId, params] as const,
  annotationHistory: (imageId?: string) => [...ImageQueryKeys.all, 'annotationHistory', imageId] as const,
  imagesDataFilter: (projectId: string, params?: ImageFiltersPayload) =>
    [...ImageQueryKeys.all, projectId, params] as const,
  imagesDataFilterPaginated: (
    projectId: string,
    filters: ImageFiltersPayload | undefined,
    sorting: SortAndCountArgs | undefined,
    targetPage: number | undefined,
  ) => [...ImageQueryKeys.imagesDataFilter(projectId, filters), sorting, targetPage] as const,
  imagesDataModel: (projectId: string, params?: Pick<ImageFiltersPayload, 'humanAnnotationLabels'>) =>
    [...ImageQueryKeys.all, projectId, params] as const,
  latestImage: (projectId: string) => [...ImageQueryKeys.all, projectId, 'latestImage'] as const,
  labelInstancesData: (projectId: string, params: ImagesDataPayload) =>
    [...ImageQueryKeys.all, projectId, params] as const,
  imagesPerIds: (projectId: string, imageIds: string[]) => [...ImageQueryKeys.all, projectId, imageIds] as const,
  imagesIds: (projectId: string, filters: ImageFiltersPayload) => [...ImageQueryKeys.all, projectId, filters] as const,
};

export function useCurrentAnnotationHistoryQuery({imageId, modelId}: {imageId?: string; modelId?: string}) {
  const projectId = useAppSelector(selectCurrentProjectId);
  const [activeConfusionMatrixCellId] = useQueryParam(confusionMatrixCellIdQueryParam);
  const confusionCell = useConfusionCellFromQueryParams();
  const evaluationCell = useEvaluationCellFromQueryParams();
  const similaritySearch = useSimilaritySearchFromQueryParams();

  const payload: ImagesDataPayload = {
    filters: {
      ...(!activeConfusionMatrixCellId && !evaluationCell && {objectId: imageId}),
      ...(confusionCell && {confusionCell}),
      ...(evaluationCell && {evaluationCell}),
      ...(similaritySearch && {similaritySearch}),
      includeAnnotationHistory: true,
    },
    pagination: {count: 1},
  };

  return useQuery(ImageQueryKeys.annotationHistory(imageId), ({signal}) => getImagesData(projectId, payload, signal), {
    enabled: modelId !== undefined && imageId !== undefined,
    staleTime: 1000 * 60, // 1 minute
    select: (imagesData) => {
      if (!modelId || !imagesData.data[0]?.humanAnnotationHistory) {
        return [];
      }
      return imagesData.data[0]?.humanAnnotationHistory![modelId] || [];
    },
  });
}

/**
 * This query returns the latest image for a given project.
 */
export function useLatestImageQuery({projectId}: {projectId: string}) {
  return useQuery(
    ImageQueryKeys.latestImage(projectId),
    ({signal}) => getImagesData(projectId, {pagination: {count: 1}}, signal),
    {
      staleTime: 1000 * 60 * 10,
      select: (imagesData) => imagesData.data[0],
    },
  );
}

/**
 * This query is used to get the label distribution counts for the current model, which are part of the model insights.
 * Note: this uses the /images endpoint.
 *
 * @param productTypes The product types to filter the label distribution counts by.
 * @returns The label distribution counts.
 */
export function useLabelDistributionCountsQuery({productTypes}: {productTypes: string[]}) {
  const projectId = useAppSelector(selectCurrentProjectId);
  const {currentModel} = useCurrentModel();
  const labelIds = useLabelIds({from: 'current-model'});

  const payload: ImagesDataPayload = {
    filters: {
      humanAnnotationLabels: labelIds,
    },
    pagination: {
      count: MAX_IMAGES_TOTAL,
    },
  };

  return useQuery(
    ImageQueryKeys.imagesData(projectId, payload),
    ({signal}) => getImagesData(projectId, payload, signal),
    {
      enabled: labelIds?.length > 0,
      staleTime: 1000 * 60,
      select: (imagesData): LabelDistributionCounts => {
        if (currentModel === undefined) {
          return {
            imageCounts: {},
            instanceCounts: {},
          };
        }
        return getLabelDistributionCounts({
          imageData: imagesData.data,
          currentModel,
          productTypes,
        });
      },
    },
  );
}

/**
 * This query returns the images per list of ids for a given project.
 */
export function useImagesPerIds(imageIds: string[] = []) {
  const projectId = useAppSelector(selectCurrentProjectId);
  return useQuery(
    ImageQueryKeys.imagesPerIds(projectId, imageIds),
    ({signal}) =>
      getImagesData(
        projectId,
        {
          filters: {objectIds: imageIds},
          pagination: {count: imageIds.length}, // Fetch all images at once
        },
        signal,
      ),
    {
      enabled: !!imageIds.length,
    },
  );
}

/**
 * This query returns the images ids for given filters.
 */
export function useImagesIds(imageFilters: ImagesDataPayload['filters'], enabled = true) {
  const projectId = useAppSelector(selectCurrentProjectId);

  return useQuery(
    ImageQueryKeys.imagesIds(projectId, imageFilters ?? {}),
    ({signal}) => getImagesIds(projectId, imageFilters, signal),
    {
      enabled,
    },
  );
}

export function useImagesDataQuery(params: ImagesDataPayload) {
  const projectId = useAppSelector(selectCurrentProjectId);
  return useQuery(
    ImageQueryKeys.imagesData(projectId, params),
    ({signal}) => getImagesData(projectId, params, signal),
    {
      staleTime: 1000 * 60,
    },
  );
}
