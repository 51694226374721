import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {Model} from '~redux/types/models';
import {selectCurrentProjectId} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

import {useCurrentModel} from 'src/contexts/ModelContext';
import {getModels, getModelSelectionMetric, getModelTrainingConfig} from './models';

export const ModelsQueryKeys = {
  all: ['models'],
  allModels: (projectId: string) => [...ModelsQueryKeys.all, projectId] as const,
  modelTrainingConfiguration: (projectId: string, modelId?: string) =>
    [...ModelsQueryKeys.all, projectId, modelId] as const,
  modelSelectionMetric: (projectId: string, modelId: string) => [
    ...ModelsQueryKeys.all,
    'selectionMetric',
    projectId,
    modelId,
  ],
};

export function useModelsQuery(enabled: boolean = true): UseQueryResult<Model[]> {
  const projectId = useAppSelector(selectCurrentProjectId);

  return useQuery(ModelsQueryKeys.allModels(projectId), ({signal}) => getModels(projectId, signal), {
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 60, // 1 hour
    select: (data) => {
      return data
        .map((model) => ({
          // ensure that every label item has an alias by falling back to the label.id
          ...model,
          labels: model.labels.map((label) => ({...label, alias: label.alias || label.id})),
        }))
        .sort((modelA, modelB) => modelB.createdAt - modelA.createdAt);
    },
    enabled,
  });
}

export function useSingleModelQuery(modelId: string): UseQueryResult<Model | null> {
  const projectId = useAppSelector(selectCurrentProjectId);

  return useQuery(ModelsQueryKeys.allModels(projectId), ({signal}) => getModels(projectId, signal), {
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 60, // 1 hour
    select: (models) => models.find((model) => model.id === modelId) || null,
  });
}

export function useCurrentModelHasSamplesQuery(): UseQueryResult<boolean> {
  const {currentModel} = useCurrentModel();
  const projectId = useAppSelector(selectCurrentProjectId);

  return useQuery(ModelsQueryKeys.allModels(projectId), ({signal}) => getModels(projectId, signal), {
    refetchOnWindowFocus: true,
    staleTime: 1000 * 10,
    select: (models) => {
      const model = models.find((model) => model.id === currentModel?.id);
      return model ? model.imageCount > 0 : false;
    },
  });
}

export function useCurrentModelTrainingConfigurationQuery(
  isAdmin: boolean,
  modelId?: string,
): UseQueryResult<ModelTrainingConfigurationResponse> {
  const {currentModel} = useCurrentModel();
  const projectId = useAppSelector(selectCurrentProjectId);
  return useQuery(
    ModelsQueryKeys.modelTrainingConfiguration(projectId, modelId ?? currentModel?.id),
    ({signal}) => getModelTrainingConfig(projectId, modelId ?? currentModel!.id, signal),
    {
      enabled: (!!modelId || !!currentModel?.id) && isAdmin,
    },
  );
}

/**
 * Hook to fetch the selection metric for a model
 */
export function useModelSelectionMetricQuery(): UseQueryResult<SelectionMetricResponse> {
  const {currentModel} = useCurrentModel();
  const projectId = useAppSelector(selectCurrentProjectId);

  return useQuery(
    ModelsQueryKeys.modelSelectionMetric(projectId, currentModel!.id),
    ({signal}) => getModelSelectionMetric(projectId, currentModel!.id, signal),
    {
      enabled: !!currentModel?.id,
      staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    },
  );
}
