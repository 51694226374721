import {lowerCaseArray} from '~utils/miscUtils';

import {mapStringNullToNull} from '~components/images/image-overview/toolbar/utils';
import {NO_DEFECTS_KEY, NO_OBJECTS_KEY} from 'src/constants/constants';
import {ImageFiltersPayload} from './images';

export const processFilters = (filters: ImageFiltersPayload | undefined) => {
  let productTypes;
  let meta;

  if (filters?.meta) {
    meta = mapStringNullToNull(filters.meta);
  }

  if (Array.isArray(filters?.productType)) {
    productTypes = filters.productType;
  } else if (filters?.productType) {
    productTypes = [filters.productType];
  }

  productTypes = productTypes?.reduce(
    (acc, productType) => {
      if (productType === 'null') {
        return [...acc, null];
      }
      return [...acc, productType];
    },
    [] as (string | null)[],
  );

  return {
    ...filters,
    machinePredictionLabels: filters?.machinePredictionLabels?.filter(
      (label) => label !== NO_DEFECTS_KEY && label !== NO_OBJECTS_KEY,
    ),
    humanAnnotationLabels: filters?.humanAnnotationLabels?.filter(
      (label) => label !== NO_DEFECTS_KEY && label !== NO_OBJECTS_KEY,
    ),
    isPredictedOnlyByModel: filters?.isPredictedOnlyByModel,
    withoutMachineDefectPredictions: filters?.withoutMachineDefectPredictions,
    withoutMachineObjectPredictions: filters?.withoutMachineObjectPredictions,
    withNoImages: Boolean(filters?.withNoImages),
    latestLabelers: lowerCaseArray(filters?.latestLabelers),
    labelers: lowerCaseArray(filters?.labelers),
    productType: productTypes,
    meta,
  };
};
