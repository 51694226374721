import {useCallback, useMemo, useState} from 'react';

interface UseCheckedItemsProps {
  allItemsIds?: string[];
}

export function useCheckedItems({allItemsIds}: UseCheckedItemsProps) {
  const [checkedItemIds, setCheckedItemIds] = useState<string[]>([]);
  const checkedIdsSet = useMemo(() => new Set(checkedItemIds), [checkedItemIds]);

  const setAllChecked = useCallback(
    (checked: boolean) => {
      setCheckedItemIds(checked ? allItemsIds ?? [] : []);
    },
    [allItemsIds, setCheckedItemIds],
  );

  const setChecked = useCallback((itemId: string) => {
    setCheckedItemIds((prev) => {
      const isChecked = prev.includes(itemId);
      return isChecked ? prev.filter((id) => id !== itemId) : [...prev, itemId];
    });
  }, []);

  const setMultipleChecked = useCallback(
    (itemIds: string[]) => {
      let newCheckedIds: string[] = [];

      const areAllInList = itemIds.every((id) => checkedIdsSet.has(id));

      if (areAllInList) {
        newCheckedIds = Array.from(checkedIdsSet).filter((id) => !itemIds.includes(id));
      } else {
        newCheckedIds = [...new Set([...checkedIdsSet, ...itemIds])];
      }

      setCheckedItemIds(newCheckedIds);
    },
    [checkedIdsSet, setCheckedItemIds],
  );

  return {
    checkedItemIds,
    setChecked,
    setAllChecked,
    allChecked: !!allItemsIds?.length && checkedItemIds.length === allItemsIds.length,
    setMultipleChecked,
  };
}
