import {useRouter} from 'next/router';
import {createContext, ReactNode, useContext, useEffect, useRef} from 'react';

import {useImagesIds} from '~api/images.queries';
import {useLabelInstancesIds} from '~api/label-instances.queries';
import {Route} from '~utils/routeUtil';
import {selectCurrentModel} from '~redux/reducers/annotatorReducer';
import {useAppSelector} from '~redux/index';

import {useImageFilters} from '~components/images/image-overview/imageOverview.hooks';
import {ImageOverviewMode} from '~components/images/utils/image-mode-mixins';
import {useLabelItems} from '~components/models/model.hooks';
import {useCheckedItems} from './checkedItems.hooks';

export const CombinedCheckedItemsContext = createContext<CombinedCheckedItemsContextValue>(
  {} as CombinedCheckedItemsContextValue,
);

export const useCombinedCheckedItems = () => {
  return useContext(CombinedCheckedItemsContext) as CombinedCheckedItemsContextValue;
};

export const CombinedCheckedItemsProvider = (props: {children: ReactNode; mode: ImageOverviewMode}) => {
  const currentModel = useAppSelector(selectCurrentModel);
  const modelLabelItems = useLabelItems({from: 'current-model-or-project', model: currentModel});
  const [imageFilters] = useImageFilters(props.mode, modelLabelItems);

  // Images state management
  const imageIdsQuery = useImagesIds(imageFilters);

  // Label instances state management
  const labelInstancesIdsQuery = useLabelInstancesIds(imageFilters);

  // Initialize checked items hooks
  const imageCheckedItemsState = useCheckedItems({
    allItemsIds: imageIdsQuery.data?.imageIds,
  });

  const labelInstancesCheckedItemsState = useCheckedItems({
    allItemsIds: labelInstancesIdsQuery.data?.ids,
  });

  // Route change handling
  const router = useRouter();
  const previousRoute = useRef<string | null>(null);

  useEffect(() => {
    const handleRouteChange = (currentRoute: Route) => {
      const shouldCacheBeKept =
        (currentRoute.includes(Route.archive) && previousRoute.current?.includes(Route.archive)) ||
        (currentRoute.includes(Route.modelSamples) && previousRoute.current?.includes(Route.modelSamples)) ||
        (currentRoute.includes(Route.modelAddSamples) && previousRoute.current?.includes(Route.modelAddSamples)) ||
        (currentRoute.includes(Route.modelFinetune) && previousRoute.current?.includes(Route.modelFinetune)) ||
        previousRoute.current === null;

      if (!shouldCacheBeKept) {
        imageCheckedItemsState.setAllChecked(false);
        labelInstancesCheckedItemsState.setAllChecked(false);
      }

      previousRoute.current = currentRoute;
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [imageCheckedItemsState, labelInstancesCheckedItemsState, router.events]);

  const value: CombinedCheckedItemsContextValue = {
    imageCheckedItemsState,
    labelInstancesCheckedItemsState,
  };

  return <CombinedCheckedItemsContext.Provider value={value}>{props.children}</CombinedCheckedItemsContext.Provider>;
};
