import ky from 'ky';

import {getOptions} from '~api/api';
import {NewOrExistingLabelItem, UnsavedLabelItem} from '~redux/types/images';
import {InspectionSubType, InspectionType, Model} from '~redux/types/models';

const modelsEndpoint = `${process.env.AUTH_API}/models`;

export type ModelsResponse = Model[];
export type SingleModelResponse = Model;

export type CreateModelPayload = {
  name: string;
  inspectionType: InspectionType;
  inspectionSubtype?: InspectionSubType;
  labels: UnsavedLabelItem[];
};

export type UpdateModelPayload = {
  id: string;
  name: string;
  labels: NewOrExistingLabelItem[];
};

export const getModels = (projectId: string, reactQuerySignal?: AbortSignal) => {
  return ky
    .get(`${modelsEndpoint}/${projectId}`, getOptions({signalId: 'getModels', reactQuerySignal}))
    .json<ModelsResponse>();
};

export const postModel = (projectId: string, newModel: CreateModelPayload, reactQuerySignal?: AbortSignal) => {
  return ky
    .post(`${modelsEndpoint}/${projectId}`, getOptions({signalId: 'postModel', reactQuerySignal, data: newModel}))
    .json<ModelsResponse>();
};

export const putModel = (projectId: string, updatedModel: UpdateModelPayload, reactQuerySignal?: AbortSignal) => {
  return ky
    .put(
      `${modelsEndpoint}/${projectId}/${updatedModel.id}`,
      getOptions({signalId: 'putModel', reactQuerySignal, data: updatedModel}),
    )
    .json<SingleModelResponse>();
};

export const deleteModelLabel = (
  projectId: string,
  modelId: string,
  labelId: string,
  reactQuerySignal?: AbortSignal,
) => {
  return ky
    .delete(
      `${modelsEndpoint}/${projectId}/${modelId}/${labelId}`,
      getOptions({signalId: 'deleteModelLabel', reactQuerySignal}),
    )
    .json<SingleModelResponse>();
};

export const deleteModel = (projectId: string, modelId: string, reactQuerySignal?: AbortSignal) => {
  return ky
    .delete(`${modelsEndpoint}/${projectId}/${modelId}`, getOptions({signalId: 'deleteModelLabel', reactQuerySignal}))
    .json<Model[]>();
};

export const getModelTrainingConfig = (projectId: string, modelId: string, reactQuerySignal?: AbortSignal) => {
  return ky
    .get(`${modelsEndpoint}/${projectId}/${modelId}/training_config`, {
      ...getOptions({
        signalId: 'getModelTrainingConfig',
        reactQuerySignal,
        customHeaders: {
          'X-Snake-Case-Resp': 'true',
        },
      }),
      parseJson: (text) => {
        const parseableText = text
          .replace(/Infinity/g, '"inf"')
          .replace(/-Infinity/g, '"-inf"')
          .replace(/NaN/g, '"nan"');

        return JSON.parse(parseableText);
      },
    })
    .json<ModelTrainingConfigurationResponse>();
};

export const postModelTrainingConfig = (
  projectId: string,
  modelId: string,
  newTrainingConfig: ModelTrainingConfigurationPayload,
  reactQuerySignal?: AbortSignal,
) => {
  return ky
    .post(
      `${modelsEndpoint}/${projectId}/${modelId}/training_config_fe`,
      getOptions({signalId: 'postTrainingConfig', reactQuerySignal, data: newTrainingConfig}),
    )
    .json<ModelTrainingConfigurationResponse>();
};

/**
 * @throws {HTTPError} with content {@link ApiErrorResponse}
 */
export const postUnpublishModel = ({projectId, modelId}: AutoMlModelArgs) => {
  return ky
    .post(
      `${modelsEndpoint}/${projectId}/${modelId}/unpublish`,
      getOptions({
        signalId: 'postUnpublishModel',
      }),
    )
    .json<{success: boolean}>();
};

export const putArchiveModel = ({projectId, modelId}: {projectId: string; modelId: string}) => {
  return ky
    .put(
      `${modelsEndpoint}/${projectId}/${modelId}/archive`,
      getOptions({
        signalId: 'putArchiveModel',
      }),
    )
    .json<{success: boolean}>();
};

export const putUnarchiveModel = ({projectId, modelId}: {projectId: string; modelId: string}) => {
  return ky
    .put(
      `${modelsEndpoint}/${projectId}/${modelId}/unarchive`,
      getOptions({
        signalId: 'putUnarchiveModel',
      }),
    )
    .json<{success: boolean}>();
};
/**
 * Get the selection metric for a model
 * @throws {HTTPError} with content {@link ApiErrorResponse}
 */
export const getModelSelectionMetric = (projectId: string, modelId: string, reactQuerySignal?: AbortSignal) => {
  return ky
    .get(
      `${modelsEndpoint}/${projectId}/${modelId}/selection_metrics`,
      getOptions({
        signalId: 'getModelSelectionMetric',
        reactQuerySignal,
      }),
    )
    .json<SelectionMetricResponse>();
};
